<template>
  <div class="orderTest-container">
    <div
      style="display: flex; justify-content: space-between; margin-bottom: 10px"
    >
      <el-button @click="backRouter">返回</el-button>
      <el-button type="warning" @click="handleExport">导出</el-button>
    </div>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column align="center" width="50">
        <template #default="{ row, $index }">
          <div v-if="row.allot_code.indexOf('合计') !== -1"></div>
          <div v-else>{{ $index + 1 }}</div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(list, index) in columns"
        :key="index"
        :label="list.label"
        :align="list.align"
        :prop="list.prop"
        width=""
      >
        <template #default="{ row }">
          <div
            v-if="list.label == '单号'"
            class="underline"
            @click="handleOrderDetail(row)"
          >
            {{ row[list.prop] }}
          </div>
          <div v-else>{{ row[list.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="row.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="row.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>
    <diaoboOrder ref="diaoboOrder"></diaoboOrder>
  </div>
</template>
<script>
  import { downloadFile, postAction } from '@/api/Employee'
  import diaoboOrder from '@/views/project/treasury/requisition/documents/components/AddGoods.vue'

  export default {
    name: 'CarDepotAllotDetail',
    components: { diaoboOrder },
    beforeRouteEnter(to, from, next) {
      next((v) => {
        v.row = to.params
        v.fetchData()
      })
    },
    data() {
      return {
        row: {},
        loading: false,
        list: [],
        columns: [
          {
            order: 1,
            label: '单号',
            align: 'center',
            prop: 'allot_code',
            width: '',
          },
          {
            order: 2,
            label: '出货仓库',
            align: 'center',
            prop: 'out_name',
            width: '',
          },
          {
            order: 3,
            label: '入货仓库',
            align: 'center',
            prop: 'in_name',
            width: '',
          },
          {
            order: 4,
            label: '申请日期',
            align: 'center',
            prop: 'add_time',
            width: '',
          },
          {
            order: 5,
            label: '审核时间',
            align: 'center',
            prop: 'check_time',
            width: '',
          },
          {
            order: 5.5,
            label: '商品编码',
            align: 'center',
            prop: 'goods_id',
            width: '',
          },
          {
            order: 6,
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 7,
            label: '单位换算',
            align: 'center',
            prop: 'goods_convert',
            width: '',
          },
          {
            order: 8,
            label: '申请数量',
            align: 'center',
            prop: 'apply_goods_num',
            width: '',
          },
          {
            order: 9,
            label: '实际入库',
            align: 'center',
            prop: 'goods_num',
            width: '',
          },
          {
            order: 9.4,
            label: '单价',
            align: 'center',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 9.5,
            label: '金额',
            align: 'center',
            prop: 'total_price',
            width: '',
          },
          {
            order: 10,
            label: '备注',
            align: 'center',
            prop: 'remark',
            width: '',
          },
        ],
        total: 0,
        footer: {},
      }
    },
    computed: {
      // 入库ID
      inId() {
        // guide_id=1 in_id = depot_id(仓库id),guide_id=2 in_id=car_id(车辆id)
        let id = this.row.guide == 1 ? this.row.car_id : this.row.depot_id
        return id
      },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        let data = {
          ...this.row,
          ...{ in_id: this.inId },
        }
        try {
          let res = await postAction('/depotAdmin/bill-allot/detail-list', data)
          this.list = res.data.rows
          this.total = Number(res.data.total)
          console.log('res', res)
          this.footer = res.data.footer

          this.loading = false
        } catch (err) {
          this.loading = false
        }
      },
      backRouter() {
        this.$router.push({
          name: 'CarDepotAllot',
        })
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计'
            return
          }

          sums[9] = this.footer.apply_goods_num
          sums[10] = this.footer.goods_num

          // const values = data.map((item) => Number(item[column.property]))
          // if (!values.every((value) => isNaN(value))) {
          //   sums[index] = values.reduce((prev, curr) => {
          //     const value = Number(curr)
          //     if (!isNaN(value)) {
          //       return prev + curr
          //     } else {
          //       return prev
          //     }
          //   }, 0)
          //   sums[index] += ' 元'
          // } else {
          //   sums[index] = 'N/A'
          // }
        })

        return sums
      },
      handleExport() {
        downloadFile(
          '/depotAdmin/bill-allot/export-detail-list',
          '调拨单明细表.xlsx',
          this.row
        )
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.row.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.row.pageNo = val
        this.fetchData()
      },
      handleOrderDetail(row) {
        this.$refs.diaoboOrder.dialogVisible = true
        this.$refs.diaoboOrder.type = 1
        this.$refs.diaoboOrder.handlerlist(row)
      },
    },
  }
</script>
<style lang="scss" scoped></style>
