var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "margin-bottom": "10px",
          },
        },
        [
          _c("el-button", { on: { click: _vm.backRouter } }, [_vm._v("返回")]),
          _c(
            "el-button",
            { attrs: { type: "warning" }, on: { click: _vm.handleExport } },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            stripe: "",
            data: _vm.list,
            "summary-method": _vm.getSummaries,
            "show-summary": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    row.allot_code.indexOf("合计") !== -1
                      ? _c("div")
                      : _c("div", [_vm._v(_vm._s($index + 1))]),
                  ]
                },
              },
            ]),
          }),
          _vm._l(_vm.columns, function (list, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                label: list.label,
                align: list.align,
                prop: list.prop,
                width: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        list.label == "单号"
                          ? _c(
                              "div",
                              {
                                staticClass: "underline",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOrderDetail(row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row[list.prop]) + " ")]
                            )
                          : _c("div", [_vm._v(_vm._s(row[list.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.row.pageNo,
          "page-sizes": [10, 20, 50, 100],
          "page-size": _vm.row.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("diaoboOrder", { ref: "diaoboOrder" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }